import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import ValueProps from "../components/Repeating/ValueProps";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import MortgageOptions from "../components/Repeating/MortgageOptions";
import houseSVG from "../images/Landing Page/svg/house.svg"
import cashSVG from "../images/Landing Page/svg/cash.svg"
import gradSVG from "../images/Landing Page/svg/grad-hat.svg"

const Page = ({ data }) => {
  return (
    <Layout pageType={"Landing"}>
      <SearchEngineOptimization
        title=""
        description=""
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-20 md:mb-28 md:pt-12">
        <div className="container">
          <div className="grid grid-cols-1 items-center  gap-y-10  pt-[15px]  md:grid-cols-2 md:gap-x-10 md:px-0 md:pt-0 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <div className="mb-3.5 text-lg font-extrabold uppercase text-secondary-500">
                Trusted since 1989
              </div>
              <h1 className=" lg:text-[75px] lg:leading-[85px]">
                Destination: Home Loan.
              </h1>
              <p className=" px-0 text-black  md:px-[30px] lg:text-[30px] lg:leading-[40px]">
                Your path to a home mortgage begins here.
              </p>
              <div className="grid grid-cols-2 gap-x-2 overflow-hidden">
                <ButtonSolid
                  className=" mr-5 inline-block min-w-[100%] lg:min-w-[170px]"
                  href="/request-rates/"
                  text="Get Started"
                  altStyle={2}
                />
                <ButtonSolid
                  className=" min-w-[100%] md:invisible md:hidden "
                  href="/request-rates/"
                  text="Request Rates"
                />
              </div>
            </div>
            <div className="order-1 text-center md:order-2">
              <div className="hidden md:block">
                <StaticImage
                  src="../images/common/purchase_hero_image.png"
                  loading="eager"
                  width={560}
                />
              </div>
              <a href="tel:8554360675" className="block md:hidden">
                <StaticImage
                  src="../images/common/purchase_hero_image.png"
                  loading="eager"
                  width={560}
                />
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-26">
        <div className="container">
          <header className="mb-10 text-center md:mb-14">
            <h2>
              Home financing. <br></br>We're ready when you are.
            </h2>
          </header>
          <div className="grid gap-y-8 md:grid-cols-3 md:gap-x-6 lg:gap-x-10">
            <div className="flex flex-col justify-between rounded-3xl bg-secondary-500/60 px-2 py-8 shadow-4xl">
              <div className="text-center">
                <img
                  src={houseSVG}
                  alt="Lower Your Payment"
                  className="mx-auto mb-6"
                />

                <h3 className="heading-five">I’m Ready To Buy</h3>
              </div>

              <div className="mb-6 text-center ">
                <div className="h-px w-full "></div>
                <p className="mb-0 text-typography-heading">
                  Can’t wait to get the keys to a new home? Apply now to secure
                  your financing.
                </p>
              </div>

              <ButtonSolid
                href="/refinance/"
                text="Get Financing Now!"
                className="!text-md mx-auto w-9/12"
              />
            </div>
            <div className="flex flex-col justify-between rounded-3xl bg-secondary-500/60 px-2 py-8 shadow-4xl">
              <div className="text-center">
                <img
                  src={cashSVG}
                  alt="Lower Your Payment"
                  className="mx-auto mb-6"
                />

                <h3 className="heading-five">I’m Just Starting To Look</h3>
              </div>

              <div className="mb-6 text-center ">
                <div className="h-px w-full "></div>
                <p className="mb-0 text-typography-heading">
                  Ready to house hunt? Prequalify to find out how much home you
                  can afford.
                </p>
                <div className="h-px w-full "></div>
              </div>

              <ButtonSolid
                href="/refinance/"
                text="Get Pre-Approved Now!"
                className="!text-md mx-auto w-9/12"
              />
            </div>
            <div className="flex flex-col justify-between rounded-3xl bg-secondary-500/60 px-2 py-8 shadow-4xl">
              <div className="text-center">
                <img
                  src={gradSVG}
                  alt="Lower Your Payment"
                  className="mx-auto mb-6"
                />

                <h3 className="heading-five">I’m Doing My Homework</h3>
              </div>

              <div className="mb-6 text-center ">
                <div className="h-px w-full "></div>
                <p className="mb-0 text-typography-heading">
                  Want to educate yourself about home buying before you take the
                  leap?
                </p>
                <div className="h-px w-full "></div>
              </div>

              <ButtonSolid
                href="/refinance/"
                text="Learn More!"
                className="!text-md mx-auto w-9/12"
              />
            </div>
          </div>
        </div>
      </section>

      <MortgageOptions className="mb-20 md:mb-28 md:pt-12 " />
      <section className="mb-6 md:mb-6">
        <div className="container">
          <header className="mb-10 text-center md:mb-14">
            <h2>Estimate your payment.</h2>
          </header>

          <iframe
            src="https://mortgage-tools.flywheelsites.com/home-mortgage-calculator/"
            width="100%"
            height="100%"
            title="Mortgage Calculator"
            frameBorder="0"
            className="h-[1100px] md:h-[700px]"
          ></iframe>
        </div>
      </section>

      <Testimonials />
      <ValueProps />

      <div className="container">
        <section className="mb-20 rounded-3xl bg-primary-900 py-20 px-4 md:mb-32">
          <div className="mx-auto max-w-[688px] text-center">
            <h2 className="mb-4 text-white">
              Get in Touch With Our Team Today!
            </h2>
            <p className="text-primary-50">
              We’re happy to connect with you and answer any questions you might
              have. Reach our today!
            </p>
            <div className="flex flex-wrap items-center justify-center space-y-4 md:flex-nowrap md:space-y-0 md:space-x-4">
              <ButtonSolid
                href="/home-purchase/"
                text="Apply Now!"
                className="w-full !text-lg md:w-auto md:min-w-[200px]"
              />
              <ButtonSolid
                href="tel:866-299-1600"
                text="(866) 299-1600"
                altStyle={2}
                className="w-full !text-lg md:w-auto md:min-w-[200px]"
              />
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
